import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Select from "react-select"
import firstBy from "thenby"
import SearchContext from "../context/SearchContext"

const INITIAL_VALUE = {
  original: [],
  format: [],
  genre: [],
  target: []
};
export default function InputSearch() {
  const { refinementList, setRefinementList } = useContext(SearchContext)
  const { genres, originals, formats, targets } = useStaticQuery(
    graphql`
        query {
            originals: allWordpressWpOriginal {
                nodes {
                    name
                }
            }
            genres: allWordpressWpGenre {
                nodes {
                    name
                }
            }
            formats: allWordpressWpFormat {
                nodes {
                    name
                }
            }
            targets: allWordpressWpTarget {
                nodes {
                    name
                }
            }
        }
    `
  )
  const options = [
    ...originals.nodes.map(({ name }) => ({ label: name, value: `original:${name}` })),
    ...genres.nodes.map(({ name }) => ({ label: name, value: `genre:${name}` })),
    ...formats.nodes.map(({ name }) => ({ label: name, value: `format:${name}` })),
    ...targets.nodes.map(({ name }) => ({ label: name, value: `target:${name}` }))
  ]
    .sort((a, b) => a.label.localeCompare(b.label))
  const countries = ["English", "Italiano", "Español"]
  const locals = ["Glocal", "Local"]

  function countryCompare(a, b) {
    return countries.indexOf(a) - countries.indexOf(b)
  }

  function localCompare(a, b) {
    return locals.indexOf(a) - locals.indexOf(b)
  }

  const sorter = firstBy(String, { cmp: countryCompare })
    .thenBy(String, { cmp: localCompare })
    .thenBy(String)

  function reducer(accumulator, current) {
    const [name, item] = current.value.split(":")
    return {
      ...accumulator,
      [name]: [...accumulator[name], item].sort(sorter)
    }
  }

  function onChange(values) {
    if (values) {
      const result = values.reduce(reducer, INITIAL_VALUE)
      setRefinementList(result)
      return
    }
    setRefinementList(INITIAL_VALUE)
  }

  const value = options.filter(option =>
    refinementList.original.map(name => `original:${name}`).includes(option.value) ||
    refinementList.genre.map(name => `genre:${name}`).includes(option.value) ||
    refinementList.format.map(name => `format:${name}`).includes(option.value) ||
    refinementList.target.map(name => `target:${name}`).includes(option.value)
  )
  return (
    <Select
      closeMenuOnSelect
      openMenuOnFocus={false}
      openMenuOnClick={false}
      onChange={onChange}
      value={value}
      isMulti
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      options={options}
      placeholder="Series, Features, Animation, Original, Derivative, Drama, Comedy, Genre, etc."
      styles={{
        placeholder: (provided) => ({
          // ...provided,
          color: "#bebebe",
          marginLeft: 2,
          marginRight: 2,
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          boxSizing: "border-box",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "97%",
          fontSize: ".9em",
        }),
        control: (provided, state) => ({
          // ...provided,
          alignItems: "center",
          border: "2px solid transparent",
          borderColor: state.isFocused ? "#ffba39" : "transparent",
          display: "inline-flex",
          height: "2.2em",
          justifyContent: "flex-start",
          lineHeight: 1.5,
          paddingBottom: "calc(0.5em - 2px)",
          paddingTop: "calc(0.5em - 2px)",
          position: "relative",
          verticalAlign: "top",

          backgroundColor: "#f9f9f9",
          color: "#363636",

          borderRadius: "290486px",
          paddingLeft: "10px",
          paddingRight: "10px",
          fontSize: ".9em",

          boxShadow: "none",
          maxWidth: "100%",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }),
        option: (provided, state) => {
          return {
            ...provided,
            backgroundColor: state.isFocused || state.isSelected ? "#ffba39" : "#f9f9f9",
            color: "#1b1b1b"
          }
        },
        multiValue: (provided, state) => ({
          ...provided,
          borderRadius: 1000,
          paddingLeft: 5,
          fontSize: ".9em"
        }),
        multiValueRemove: (provided, state) => ({
          ...provided,
          paddingLeft: 3,
          borderTopRightRadius: 18,
          borderBottomRightRadius: 18,
          borderRadius: 0,
          cursor: 'pointer',
          "&:hover": {
            backgroundColor: "#ffba39",
            color: "#1b1b1b"
          }
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          whiteSpace: "nowrap"
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          flexWrap: "nowrap"
        })
      }}
    />
  )
}
