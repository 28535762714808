import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import classNames from "classnames"

import useCategories from "../../hooks/use-categories"
import Selection from "../selection"
import FilterRow from "./filter-row"
import SearchContext from "../../context/SearchContext"

const StyledWrapper = styled.section`
  animation-duration: 500ms;
  .slick-prev {
    left: -30px;
  }
  .slick-next {
    right: -30px;
  }
  .slick-prev, .slick-next {
    height: 30px;
    width: 30px;
    &:before, &:before {
      font-size: 35px;
      color: #faba3a;
    }
  }
`
export default function Filters({ isOpen }) {
  const { originals, formats, genres, targets } = useCategories()
  const { refinementList } = useContext(SearchContext)
  const tags = [
    ...refinementList.original,
      ...refinementList.format,
      ...refinementList.genre,
      ...refinementList.target
  ]

  return (
    <>
      <StyledWrapper
        className={classNames(
          !isOpen && "is-hidden"
          // isOpen && "animate__animated animate__slideDown"
        )}
      >
        <div className="container is-widescreen is-fluid has-text-centered">
          <FilterRow name="original" values={originals}/>
          <hr/>
          <FilterRow name="format" values={formats}/>
          <hr/>
          <FilterRow name="genre" values={genres}/>
          <hr/>
          <FilterRow name="target" values={targets}/>

        </div>
        <hr />
      </StyledWrapper>
      {(tags.length > 0 || isOpen) && (<Selection/>)}
    </>
  )
}

Filters.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}