import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styled from "styled-components"

const StyledButton = styled.button`
  width: calc(100% - 20px);
  margin: 0 auto;
  font-size: 1.25rem !important;
`
export default function Filter({ isActive, value, onClick }) {
  return (
    <StyledButton
      type="button"
      className={classNames("button is-medium is-rounded", isActive && "is-primary")}
      onClick={onClick}
    >
      {value}
    </StyledButton>
  )
}
Filter.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
}
