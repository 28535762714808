import React, { useContext } from "react"
import styled from "styled-components"
import SearchContext from "../context/SearchContext"

const StyledWrapper = styled.div`
  //.subtitle span:not(:last-child):after {
  //  content: ", ";
  //}
  .selection-container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: center;
  .selection-item {
    background-color: hsl(0,0%,90%);
    border-radius: 1000px;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    padding-left: 5px;
    font-size: .9em;
    .text {
      border-radius: 2px;
      color: hsl(0,0%,20%);
      font-size: 85%;
      overflow: hidden;
      padding: 3px;
      padding-left: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }
    .remove {
      align-items: center;
      border-radius: 0;
      display: flex;
      padding-left: 3px;
      padding-right: 4px;
      box-sizing: border-box;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      color: #363636;
      cursor: pointer;
      &:hover {
        background-color: #ffba39;
        color: #1b1b1b;
      }
      svg {
        display: inline-block;
        fill: currentColor;
        line-height: 1;
        stroke: currentColor;
        stroke-width: 0;
      }
    }
  }
  }
`
export default function Selection() {
  const { refinementList, setRefinementList, reset } = useContext(SearchContext)
  const tags = [
    ...refinementList.original,
    ...[
      ...refinementList.format,
      ...refinementList.genre,
      ...refinementList.target
    ].sort((a, b) => a.localeCompare(b))
  ]
  function onRemove(value) {
    setRefinementList({
      original :refinementList.original.filter(element => element !== value),
      format :refinementList.format.filter(element => element !== value),
      genre :refinementList.genre.filter(element => element !== value),
      target :refinementList.target.filter(element => element !== value),
    })
  }
  return (
    <StyledWrapper className="title is-3">
      <h3 className="title">
        Your selections
        {" "}
        {tags.length > 0 && (
          <button type="button" className="is-link" onClick={reset}>
            <span className="icon is-small">
              <i className="fas fa-times"/>
            </span>
            {" "}
            <span>Clear</span>
          </button>
        )}
      </h3>
      <div className="subtitle has-text-centered">
        <div className="selection-container">
          {tags.map(value => (
            <div key={value} className="selection-item">
              <div className="text">{value}</div>
              <div className="remove" onClick={() => onRemove(value)}>
                <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                  <path
                    d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            </div>
          ))}
        </div>
        {tags.length === 0 && "Nothing selected"}
      </div>
    </StyledWrapper>
  )
}
