import React, { useContext } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick";
import firstBy from "thenby"

import Filter from "./filter"
import SearchContext from "../../context/SearchContext"

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

export default function FilterRow({ name, values }) {
  const { refinementList, setRefinementList } = useContext(SearchContext)
  const countries = ["English", "Italiano", "Español"]
  const locals = ["Glocal", "Local"]
  const filters = name === "target" ?
    [
      ...countries,
      ...locals,
      ...values.filter(v => !countries.includes(v) && !locals.includes(v))
    ] :
    values

  function countryCompare(a, b) {
    return countries.indexOf(a) - countries.indexOf(b)
  }
  function localCompare(a, b) {
    return locals.indexOf(a) - locals.indexOf(b)
  }

  const sorter = firstBy(String, { cmp: countryCompare })
    .thenBy(String, { cmp: localCompare })
    .thenBy(String)

  function toggle(arr, value) {
    if (arr.includes(value)) {
      return arr.filter(element => element !== value).sort(sorter)
    }
    return [...arr, value].sort(sorter)
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    responsive: [
      // {
      //   breakpoint: 1500,
      //   settings: {
      //     slidesToShow: 5,
      //   }
      // },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {filters.map((item, index) => (
        <Filter
          isActive={refinementList[name].includes(item)}
          key={index}
          value={item}
          onClick={() => setRefinementList({
            ...refinementList,
            [name]: toggle(refinementList[name], item)
          })}
        />
      ))}
    </Slider>
  );
}
FilterRow.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  name: PropTypes.string.isRequired
}
