import { useStaticQuery, graphql } from "gatsby"

export default function useCategories() {
  const query = useStaticQuery(graphql`
      query Categories {
          genres: allWordpressWpGenre {
              nodes {
                  id: wordpress_id
                  name
              }
          }
          targets: allWordpressWpTarget {
              nodes {
                  id: wordpress_id
                  name
              }
          }
          formats: allWordpressWpFormat {
              nodes {
                  id: wordpress_id
                  name
              }
          }
          originals: allWordpressWpOriginal {
              nodes {
                  id: wordpress_id
                  name
              }
          }
      }
  `)
  const mapper = (({ name }) => name)
  const originals = query.originals.nodes.map(mapper)
  const formats = query.formats.nodes.map(mapper)
  const genres = query.genres.nodes.map(mapper)
  const targets = query.targets.nodes.map(mapper)
  return { originals, formats, genres, targets }
}