import React, { useState } from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Layout from "../layouts/layout"
import Filters from "../components/filters"
import Results from "../components/results"
import InputSearch from "../components/input-search"

const StyledContainer = styled.div`
  //button.is-primary {
  //  &:hover {
  //    background-color: silver;
  //    color: 
  //  }
  //}
`

export default function Home() {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Layout title="IP Management & Content Development">
      <StyledContainer className="container is-widescreen has-text-centered">
        <h1 className="title has-text-primary">
          Let's find your next project!
        </h1>
        <h2 className="subtitle is-6">
          We have put together a team of authors and creative talent from all over the world, <br />
          in order to maintain a comprehensive portfolio with many different possibilities
        </h2>
        <div className="columns is-centered">
          <div className="column is-7">
            <form onSubmit={event => event.preventDefault()}>
              <InputSearch/>
              <div className="field" style={{ marginTop: "1.5em" }}>
                <div className="control">
                  <button
                    type="button"
                    className="button is-rounded is-large is-primary"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span className="icon is-medium">
                      <i className="fas fa-filter"/>
                    </span>
                    {isOpen ?
                      (<span>Close filters</span>) :
                      (<span>Open filters</span>)}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr/>
        <Filters isOpen={isOpen} setIsOpen={setIsOpen}/>
        <Results/>
        <p className="is-size-4">
          If you can't find what you are looking for, do not hesitate to drop us an email.
          <br/>
          We have an extended offline catalog that may well meet your needs!
        </p>
        <p style={{ marginTop: "1.5em" }}>
          <Link to="/contact-us" className="button is-rounded is-medium is-primary">
            <span className="icon is-medium">
              <i className="far fa-comment"/>
            </span>
            <span>Contact us</span>
          </Link>
        </p>
      </StyledContainer>
    </Layout>
  )
}
